.ficheButton{
    margin: 0.5em 1em;
    position: relative;
    font-family: inherit;
    padding: 1em;
    /* font-size: 18px; */
    white-space: nowrap;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    box-shadow: var(--button-shadow-2);
}

.ficheButton:disabled {
    opacity: 0.5;
}

.ficheButton svg{
    width: 18px;
    height: 18px;
}


.ficheButton.famille{
    background-color: var(--main-color-2);
}

.ficheButton.produit, .ficheButton.location{
    background-color: var(--main-color); 
}

.ficheButton.taille{
    background-color: var(--main-color-3); 
}

.ficheButton.couleur{
    background-color: var(--main-color-4); 
}

.ficheButton.paiement{
    background-color: var(--main-color-3); 
    font-size: 13px;
    font-weight: 700;
}

.ficheButton.multi{
    margin-top: 1.5em;
    background-color: var(--main-color-4); 
    font-size: 13px;
    font-weight: 700;
}

.ficheButton.transparent {
    opacity: 0.5;
}

.bulle{
    background-color: var(--main-color-5);
    position: absolute;
    right: -10px;
    width: 29px;
    height: 29px;
    line-height: 29px;
    vertical-align: middle;
    border-radius: 100%;
    font-size: 12px;
    opacity: 1;
}

.bulle.tri svg{
    height: 29px;
}


.bulle.alerte{
    background-color: var(--error-bg-color);

}

.bulle.noAllotement{
    background-color: var(--no-allotement);
}

.bulle.darkRed{
    background-color: var(--allotement-surbooking);
}

.bulle.red{
    background-color: var(--allotement-red);
}

.bulle.orange{
    background-color: var(--allotement-orange);
}

.bulle.yellow{
    background-color: var(--allotement-yellow);
}

.bulle.green{
    background-color: var(--allotement-green);
}


@media screen and (max-width: 640px) {
    .ficheButton{
        font-size: 14px;
    }
    
    .ficheButton svg{
        width: 14px;
        height: 14px
    }


    .ficheButton.paiement, .ficheButton.multi{
        font-size: 12px;
    }

    .bulle{
        background-color: var(--main-color-5);
        position: absolute;
        right: -10px;
        width: 22px;
        height: 22px;
        line-height: 22px;
        vertical-align: middle;
        border-radius: 100%;
        font-size: 9px;
    }
    
}  