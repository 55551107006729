
.StatistiquesTitle {
    display: flex;
} 

.StatistiquesTitle svg {
    margin-left: auto;
    color: var(--error-color);
} 


.smallBlock, .largeBlock{
    display: flex;
    flex-direction: column;
    background-color: var(--background-color-2);
    padding: 0.5em;
    margin: 0.5em;
}

.smallBlock {
    width: calc(50% - 1em);
}

.smallBlock>span, .largeBlock>span{
    color: var(--main-color);
    font-weight: 700;
    text-align: center;    
}

.smallBlock>div, .largeBlock>div{
    display: flex;   
}


.smallBlock>div>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5em;
    font-size: 15px;
    width: 50%;
    text-align: center;
}

.smallBlock .dataN{
    font-weight: 600;

}

.smallBlock .dataN_1{
    font-size: 14px;
}

.smallBlock .pct {
    display: flex;
    justify-content: center;
}

.pct.up {
    color: var(--success-color);
}

.pct.down {
    color: var(--error-color);
}

.pct svg{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.3em;
    width: 15px;
    height: 15px;
}


.largeBlock>div>div {
    padding: 0.75em;
    font-size: 15px;
    width: 50%;
    text-align: end;
}

.largeBlock .pct {
    text-align: start;
    font-size: 20px;
    font-weight: 700;
}

.largeBlock .dataN{
    margin-right: 0.5em;
    font-size: 20px;
    font-weight: 600;

}

.largeBlock .dataN_1{
    font-size: 14px;
}

@media screen and (max-width: 780px) {
    .smallBlock, .largeBlock {
        font-size: 14px;
    }

    .smallBlock>div{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}