

.ConnectionStatus>div:nth-child(2) {
    border-bottom: 1px solid var(--text-color-light);
    padding-bottom: 2em;
    margin-bottom: 2em;
} 

.ConnectionStatus img {
    height: 25px;
    margin-right: 1em;
} 

.ConnectionStatus .connection {
    display: flex;
    align-items: center;
    line-height: 25px;
    vertical-align: center;
    font-size: 18px;
    margin-bottom: 0.3em;
} 

.ConnectionStatus .connection>span:last-child {
    margin-left: auto;
} 

.ConnectionStatus .errors {
    display: flex;
    justify-content: space-between;
    padding-left: calc(25px + 1em);
    color: var(--error-color);
} 