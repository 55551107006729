.homeHeader{
    width: 100vw;
    height: fit-content;
    margin: 0;
    padding: 0 1em;
    display: flex;
    align-items: center;
}


.homeHeader img {
    cursor: pointer;
    width: 200px;
    object-fit: contain;
    margin-right: auto;
}

.homeHeader .help {
    background-color: transparent;
    border-radius: 100%;
    margin-right: 1em;
    height: fit-content;
}

.homeHeader .help svg{
    color: var(--main-color);
    width: 30px;
    height: 30px;
}

.homeHeader .profile {
    display: flex;
    height: fit-content;
    padding: 0.3em 1.5em;
    border-radius: 30px;
    background-color: var(--background-color-2);
}

.homeHeader .profile>div {
    color: var(--text-color);
    display: flex;
    margin-left: 1em;
    flex-direction: column;
}

.homeHeader .profile span:first-child {
    font-weight: 600;
}

.homeHeader .profile span:last-child {
    font-style: italic;
}

.homeHeader .profile svg {
    width: 30px;
    height: 30px;
}


.homeHeader svg{
    color: var(--main-color);
    height: 35px;
    width: 35px;
}

.homeFooter {
    bottom: 0.5em;
    left: 0.5em;
    color: var(--text-color);
    font-size: 12px;
    position: absolute;
}

/*------------- DROPDOWN MENU --------------*/

.homeHeader .dropdown {
    position: relative;
    width: fit-content;
    margin-right: 1em;
}

.homeHeader .dropdown .menu {
    width: 100%;
    position: absolute;
    margin: 20px 0 0 0;
    padding: 20px 0;
    z-index: 2;
    box-sizing: border-box;
    background: #FFF;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    border-radius: 6px;
}

.homeHeader .dropdown #toggleDropdown:not(:checked) ~ .menu {
    opacity: 0;
    height: 0;
    z-index: -1;
    transition: opacity 0.26s ease;
}

.homeHeader .dropdown #toggleDropdown:checked ~ .menu {
    opacity: 1;
    z-index : 100;
    transition: opacity 0.15s ease-in;
}

.homeHeader .dropdown .menu::before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    top: -10px;
    left: 50%;
    margin-left: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #FFF transparent;
}

.homeHeader .dropdown #toggleDropdown {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    height: 40px;
}

.homeHeader .dropdown .menu>div {
    color: var(--text-color);
    margin: 0 0.5em;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.homeHeader .dropdown .menu svg {
    width: 20px;
    height: 20px;
    margin-right: 1em;
    color: inherit;
}

.homeHeader .dropdown .menu>div:not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--background-color-2);
}

.homeHeader .dropdown .menu>div:not(:first-child) {
    padding-top: 10px;
}

.homeHeader .dropdown .menu>div:hover>* {
    opacity: 0.5;
}

/*------------- DROPDOWN MENU --------------*/


@media screen and (max-width: 640px) {  
    .homeHeader img {
        width: 150px;
    }

    .homeHeader .profile {
        padding: 0.5em;
    }

    .homeHeader .profile>div {
        display: none;
    }

    .homeFooter {
        position: relative;
    }

    .homeHeader .dropdown .menu {
        width: 200px;
        right: 0;
    }

    .homeHeader .dropdown .menu::before {
        left: 90%;
    }
}