.Caisse .Block {
    display: flex;
    padding-bottom: 0.5em!important;
    justify-content: center;
}

.Caisse .filtres {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, max-content);
}

.Caisse .filtresColumn{
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.filtresColumn h3 {
    width: fit-content;
}

.filtresColumn div {
    display: flex;
    align-items: center;
    margin: 0.5em;
}

.Caisse .filtres button{
    margin: 2em auto;
}

.Caisse .controleLoader {
    font-style: italic;
    color: var(--main-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Caisse .controleLoader>* {
    margin: 0 auto;
}


.Caisse .frame{
    position: relative;
    width:100%;
    height: 100%;
}

.Caisse .Block iframe{
    z-index: 10;
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Caisse .saveSettings {
    width: fit-content;
    margin-left: 0!important;
    color: var(--text-color);
    font-style: italic;
}

.Caisse .saveSettings svg{
    margin-right: 0.5em;
}


@media screen and (max-width: 640px) {
    .Caisse .filtresColumn:not(:last-child) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .Caisse .filtresColumn h3{
        display: grid;
        grid-column: 1 / -1;
    }

    .Caisse .filtresColumn:nth-child(2) span{
        display: none;
    }
}