
.ClientVente .form{
    display: grid;
    column-gap: 0.5em;
    padding: 0.5em;
    grid-template-columns: repeat(20, 1fr);
}

.ClientVente .form .nom{
    grid-column: 1 / 6;
}

.ClientVente .form .prenom {
    grid-column: 6 / 12;
}

.ClientVente .form .telephone{
    grid-column: 1 / 6;
}

.ClientVente .form .input{
    margin-bottom: 0;
}

.ClientVente .footer {
    grid-column: 12 / 20;
}

.ClientVente .form button:first-child, .form .loaderContainer{
    margin-left: auto;
}


.ClientVente .form button:nth-child(2){
    margin-left: 2em;
}



@media screen and (max-width: 640px) {

    .ClientVente .form .input {
        grid-column: 1 / 20;
    }

    
}