.Admin:has(> .Lot) {
    height: 100vh;
}

.Lot .form .id {
    grid-column: 1 / 3;
}


.Lot .form .name {
    grid-column: 1 / 9;
}


.Lot .ShopList>div {
    overflow-y: auto;
}


.Lot .ShopList thead {
    position: sticky;
    top: 0;
    z-index: 5;
}
