.errorContainer {
    width: 500px;
    position: fixed;
    bottom: 0;
    right: 0;
  }
  
  .errorMsg{
    cursor: pointer;
    display: flex;
    padding: 1em 0.75em;
    position: relative;
    z-index: 1000;
    margin: 0.5em;
  }

  .errorMsg .timeout{
    animation: fade-out .3s ease-in forwards var(--error-timeout);
    -moz-animation: fade-out .3s ease-in forwards var(--error-timeout); 
    -webkit-animation: fade-out .3s ease-in forwards var(--error-timeout); 
    -o-animation: fade-out .3s ease-in forwards var(--error-timeout);  
  }
  
  .errorMsg button{
    height: 20px;
    width: 20px;
    color: inherit;
    padding: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }
  
  .errorMsg span{
    margin: 0 1em;
    display: inline-flex;
    align-items: center;
  }

  .errorMsg div{
    margin: auto 0;
  }
  
  .errorMsg svg{
    margin: auto 0;
    color: inherit;
    width: 20px;
    height: 20px;
  }
  
  .errorMsg .progressBar{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    clear: both;
    height: 4px;
    transform: scaleX(0.5);
    transform-origin:left center;
    animation: progress-bar linear var(--error-timeout) forwards;
    -moz-animation: progress-bar linear var(--error-timeout) forwards; 
    -webkit-animation: progress-bar linear var(--error-timeout) forwards; 
    -o-animation: progress-bar linear var(--error-timeout) forwards;  
  
  }
  
  @keyframes progress-bar {
    from {
      transform: scaleX(1);
      -webkit-transform: scaleX(1);
    }
    to {
      transform: scaleX(0);
      -webkit-transform: scaleX(0);
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .errorMsg.error{
    color: var(--error-color);
    background-color: var(--error-bg-color);
  }
  
  .errorMsg.error .progressBar{
    background-color: var(--error-color);
  }
  
  .errorMsg.warning{
    color: var(--warning-color);
    background-color: var(--warning-bg-color);
  }
  
  .errorMsg.warning .progressBar{
    background-color: var(--warning-color);
  }
  
  .errorMsg.info{
    color: var(--info-color);
    background-color: var(--info-bg-color);
  }
  
  .errorMsg.info .progressBar{
    background-color: var(--info-color);
  }
  
  .errorMsg.success{
    color: var(--success-color);
    background-color: var(--success-bg-color)
  }
  
  .errorMsg.success .progressBar{
    background-color: var(--success-color);
  }

  @media screen and (max-width: 640px) {
    .errorContainer {
      width: 90%;
    }
    
  }