.ReductionAllotement {
    height: 100vh;
}

.ReductionAllotement .Block:last-child>div {
    height: 100%;    
    display: flex;
    flex-direction: column;
}

.ReductionAllotement .form {
    height: 100%;
    display: grid;
    column-gap: 0.5em;
    padding: 0.5em;
    grid-template-columns: max-content 1fr;
    grid-template-rows: repeat(3, max-content) 1fr;
}

.ReductionAllotement .form .debut {
    padding-bottom: 1em;
    grid-column: 1 / 1;
}

.ReductionAllotement .form .fin {
    grid-column: 2 / 2;
}

.ReductionAllotement .form .quantite {
    width: 150px;
    grid-column: 1 / 3;
}

.ReductionAllotement .form .commentaire {
    grid-column: 1 / 3;
}

.ReductionAllotement .form .submit {
    grid-column: 1 / 3;
    display: flex;
    justify-content: end;
}

.ReductionAllotement .form .submit button {
    margin-top: auto;
}


@media screen and (max-width: 640px) {
    .ReductionAllotement .form {
        margin-top: 1em;
    }


    .ReductionAllotement .form {
        grid-template-columns: 1fr 1fr;
    }

    .FormReductionAllotement .BlockTitle {
        display: none;
    }

}