.Allotements .ListePacks {
    overflow: auto;
}

.Allotements .buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 2em;
}

.Allotements .buttons>button {
    margin: 0 1em;
}

@media screen and (max-width: 640px) {
    .Allotements .buttons {
        margin-top: 1em;
    }

    .Allotements .FormAllotement .BlockTitle {
        display: none;
    }
}