.formFournisseur {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.EntreeStock h2 button {
    margin-left: auto;
}

.EntreeStock .frameContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.EntreeStock .frameContainer iframe{
    border: none;
    width: 100%;
    height: 100%;
}


.EntreeStock .Panier.Vente th .short {
    display: none;
}

.EntreeStock .Panier.Vente th span:not(.short) {
    display: inline;
}



@media screen and (max-width: 640px) {
    .EntreeStock .Panier.Vente th .short {
        display: inline;
    }
    
    .EntreeStock .Panier.Vente th span:not(.short) {
        display: none;
    }

    .EntreeStock .Panier.Vente .code {
        display: none;
    }

    .EntreeStock .Panier.Vente .suppr {
        width: 10%;
    }

}