.Previsions  {
    height: 100vh;
}

.Previsions .Block:last-child {
    padding: 0.5em;
}

.Previsions .frame{
    position: relative;
    width:100%;
    height: 100%;
}


.Previsions .Block iframe{
    z-index: 10;
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Previsions .filtresColumn {
    display: flex;
    flex-direction: column;
}

.Previsions .filtresColumn>button {
    margin-top: 1.5em;
    margin-left: auto;
    margin-right: auto;
}

.Previsions .FilterPacks {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Previsions .FilterPacks .buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 2em;
}

.Previsions .FilterPacks .buttons>button {
    margin: 0 1em;
}

.Previsions .Block.sorties>div:last-child {
    margin-top: auto;
}


.Previsions .Block.sorties button {
    color: var(--text-color);
    font-style: italic;
}

.Previsions .filtre span:after {
    content: "Filter les prévisions de sorties";
}

@media screen and (max-width: 640px) {
    .Previsions .Block.sorties>div:last-child {
        margin-top: 2em;
    }

    .Previsions .Block.sorties h3>span,
    .Previsions .Block.retours h3>span {
        display: none;
    }

    .Previsions .Block.sorties h3:after {
        content: "Sorties :"
    }

    .Previsions .Block.retours h3:after {
        content: "Retours :"
    }

    .Previsions .filtre span:after {
        content: "Filter";
    }
}