.Title{
    margin: 0;
    cursor: pointer;
    display: flex;
    padding : 0.75em 0.2em;
    color: var(--title-color);
    background-color: white;
    box-shadow: var(--block-shadow);
    transition: max-height 1s;
    grid-column: 1 / -1;
}

.Title>*{
    margin-top: auto;
    margin-bottom: auto;
}

.Title>button{
    width: fit-content;
    margin-right: 0;
    margin-left: auto;
    color: inherit;
}

.Title svg:first-child {
    margin-right: 1em;
}


.BlockTitle {
    display: flex;
    border-bottom: 2px solid var(--title-color);
    cursor: pointer;
}

.BlockTitle>*:nth-child(3){
    margin-left: auto;
}

.BlockTitle>*:nth-child(4){
    margin-left: 2em;
}

.BlockTitle >* {
    margin-top: auto;
    color: var(--title-color);
}

.BlockTitle>svg{
    margin-right: 0.5em;
}