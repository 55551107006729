.VideoLink{
    position: fixed;
    bottom: 0.75em;
    right: 0.75em;
    background-color: #FFF;
    border-radius: 100%;
}

.VideoLink.small{
    position: static;
    bottom: 0.75em;
    right: 0.75em;
    background-color: #FFF;
    border-radius: 100%;
}

.VideoLink>button {
    background-color: var(--main-color);
    padding: 0.75em;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#FFF;
}


.VideoLink svg{
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;

}

.VideoLink.small svg{
    width: 15px;
    height: 15px;

}

.VideoLink.small>button {
    padding: 0.5em;
}