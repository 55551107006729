
.EtatStocks .loaderContainer {
    height: 100%;
}

.tableEtat{
    width: 100%;
    table-layout:fixed;
    border-collapse: collapse;
}

.tableEtat th:first-child, .tableEtat th:nth-child(2){
    text-align: start;
}

.tableEtat td:nth-child(3), .tableEtat td:nth-child(4), .tableEtat td:nth-child(5) {
    text-align: center;
}

.tableEtat th:last-child{
    width: 100px;
}

.tableEtat th{
    background-color: var(--main-color);
    color: #FFF;
    padding: 0.6em 0.5em;
}

.tableEtat .warning td{
    color: var(--error-color)!important;
}

.tableEtat .ligneFamille{
    background-color: var(--background-color-2);
}

.tableEtat .ligneFamille td{
    padding: 0.5em;
    font-weight: 600;
}

.tableEtat .ligneProduit td{
    padding: 0.3em 0.5em;
}

.tableEtat .ligneProduit:not(:last-child) td{
    border-bottom: 1px solid var(--text-color-light);
}

.tableEtat .ligneProduit svg{
    color: var(--text-color);
    width: 15px;
    height: 15px;
}

.tableEtat .ligneProduit svg.open{
    transform: rotate(180deg);
}

.ligneTailleCouleur td{
    padding: 0.3em 0.5em;
    background-color: var(--background-color-3);
    color: var(--main-color);
}

.EtatStocks .export{
    display: flex;
    justify-content: center;
    width: 100%;
}

.EtatStocks .export svg {
    width: 50px;
    height: 50px;
}

.EtatStocks .frameContainer {
    width: 100%;
    height: 100%;
}

.EtatStocks .frameContainer iframe{
    border: none;
    width: 100%;
    height: 100%;
}

.editStock {
    padding: 1.5em;
    display: flex;
    justify-content: space-evenly;

}

table.historique tbody tr:hover {
    cursor: pointer;
    opacity: 0.5;
}

@media screen and (max-width: 640px) {
    .tableEtat td{
        font-size: 14px;
    }

    .tableEtat th:last-child{
        width: 40px;
    }

    .tableEtat td:first-child, .tableEtat th:nth-child(2){
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 30%;
    }

    .tableEtat th{
        font-size: 15px;
    }

    .tableEtat td{
        font-size: 13px;
    }
}