.date{
    /* font-size: 15px; */
    display: block;
    text-align: center;
    margin-bottom: 1em;
    width: 100%;
}

.swiper{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.swiperNavigationPrev, .swiperNavigationNext{
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 8px;
    z-index: 15;
    padding: 0;
    color: var(--text-color);
}

.swiperNavigationPrev:hover, .swiperNavigationNext:hover{
    opacity: 0.5;
}

.swiperNavigationPrev:disabled, .swiperNavigationNext:disabled{
    background-color: transparent!important;
    opacity: 0.5;
    cursor: auto;
}

.swiperNavigationPrev{
    left: 1em;
}

.swiperNavigationNext{
    right: 1em;
}
