.RetoursAnticipes .filtresColumn div {
    display: block !important;
}

.ligneFiche {
    cursor: pointer;
}

.ligneFiche svg {
    color: var(--text-color);
    width: 15px;
    height: 15px;
}

.RetoursAnticipes .table {
    table-layout: fixed;
}

.RetoursAnticipes .table th:last-child {
    width: 50px;
}

.RetoursAnticipes .table tbody:not(:last-child) tr:first-child{
    background-color: #FFF;
    border-bottom: 1px solid var(--text-color-light);
}

.RetoursAnticipes .table  tr:not(:first-child){
    background-color: var(--background-color-3);
    border-bottom: none;
}

@media screen and (max-width: 640px) {
    .RetoursAnticipes .filtresColumn:last-child {
        display: flex;
        justify-content: center ;
    }

    .ligneFiche svg {
        width: 20px;
        height: 20px;
    }

    .RetoursAnticipes .sort,
    .RetoursAnticipes .code {
        display: none;
    }
}  