.Admin {
    color: var(--text-color);
    padding: 0!important;
    column-gap: 0!important;
    grid-template-columns: 50px 1fr;
    /* height: 100vh; */
}

.Admin .menu{
    background-color: #FFF;
    box-shadow: var(--block-shadow);   
}

.Admin>.menu>div {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    width: 50px;
    height: 100vh;
}

.Admin .menu a, .Admin .menu button{
    color: var(--main-color);
    margin-top: 1em;
}

.Admin .menu .logout{
    margin-top: auto;
}

.Admin .menu svg {
    width: 35px;
    height: 35px;
}


.Admin>div:nth-child(2){
    padding: 0.5em 1em;
}

.Admin>div:nth-child(2) {
    grid-template-rows: max-content 1fr;
}

.AdminHome .Block h3 {
    display: flex;
    align-items: center;
}

.AdminHome .Block h3 svg {
    width:25px;
    height: 25px;
    margin-right: 0.5em;
}

.AdminHome .Block h3 button {
    color: inherit;
    margin-left: auto;
}

.Admin .Lot, .Admin .User.Supervisor{
    grid-template-columns: 2fr 2fr;
}

.Admin .Lot .Title, .Admin .User.Supervisor .Title {
    grid-column: 1 / 3;
}

.Admin .formShop .loaderContainer{
    width: fit-content;
}


.AdminHome {
    display: flex;
    flex-direction: column;
}

.Admin .ItemList .header {
    padding: 1.5em;
    display: flex;
    justify-content: center;
}

.Admin .ItemList .table{
    width: 100%;
}


.Admin .ItemRow td{
    padding: 0.5em;
}

.Admin .ItemRow svg {
    color: var(--text-color);
    width: 25px;
    height: 25px;
}

.Admin .ItemRow a {
    color: var(--text-color);
}

.Admin .form {
    display: grid;
    padding: 1em;
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows:auto;
    grid-column-gap: 1em;
    grid-row-gap: 0.1em;
}

.Admin .submitForm {
    grid-column: 1 / 11;
    margin-top: auto;
    display: flex;
    justify-content: end;
}



