.table{
    margin-bottom: 2em;
    width: 100%;
    border-collapse: collapse;
}


.table td{
    padding: 0.25em;
    text-align: center;
}

.table tr:first-child th {
    background-color: var(--main-color);
    color: #fff;
    padding: 0.6em 0.5em;
}

.table tr:nth-child(2) th {

    color: var(--main-color-3);
    border-bottom: 5px solid var(--main-color-3);
    padding: 0.6em 0.5em;
}

.table tbody tr {
    border-bottom: 1px solid var(--text-color-light);
}

.table tbody tr:last-child {
    border-bottom: 3px solid var(--text-color-light);
}

.table tbody tr:nth-child(2n) {
    background-color: var(--background-color-3);
}

.table .simpleInput {
    margin-left: auto;
    margin-right: auto;
}


@media screen and (max-width: 640px) {
    .table th{
        font-size: 15px;
    }

    .table td{
        font-size: 13px;
    }
}