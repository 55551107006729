.modal-container{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index:100;
    background-color: var(--bg-overlay);
    align-items: center;
    display: flex;
    justify-content: center;
}

.modal {
    height: 0px;
    width: 300px;
    overflow: hidden;
    position: relative;
    transition: width 300ms cubic-bezier(0.4, 0.0, 0.2, 1),
      height 300ms cubic-bezier(0.4, 0.0, 0.2, 1),
      box-shadow 300ms cubic-bezier(0.4, 0.0, 0.2, 1),
      border-radius 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
  
.to {
    opacity: 0;
    position: absolute;
    transition: opacity 100ms cubic-bezier(0.4, 0.0, 1, 1);
}

.to-contents {
    transform: scale(.55);
    transform-origin: 0 0;
    transition: transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

.modal-header {
    display: flex;
    justify-content: right;
    background: #FFF;
    font-size: 14px;
    height: 30px;
}

.modal-header svg {
    color: var(--text-color);
    width: 15px;
    height: 15px;

}

  
.modal-body {
    background: #FFF;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color:  var(--text-color);
    font-size: 14px;
    height: 250px;
    padding: 5px;
    width: 300px;
}

.modal-body .textInputContainer {
    margin-top: 0.5em;
}

.modal-body>span{
    /* margin: auto; */
    text-align: center;
}

.modal-footer {
    display: flex;
    justify-content: space-evenly;
    background: #FFF;
    font-size: 14px;
    height: 50px;
    width: 300px;
}

.modal-footer>*{
    margin: auto 0!important;
    font-size: 14px;
}
  
.modal.expand {
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.16);
    height: 300px;
    width: 300px;
}
.expand .from {
    opacity: 0;
    transition: opacity 100ms cubic-bezier(0.4, 0.0, 1, 1);
}

.expand .to {
    opacity: 1;
    transition: opacity 200ms 100ms cubic-bezier(0.0, 0.0, 0.2, 1);
}


.expand .to-contents {
    transform: scale(1);
}


@media screen and (max-width: 640px) {
    .modal {
        width: 90vw!important;
    }
}