.Home{
    justify-content: center;
    padding: 0!important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
  
.Home .buttonWrapper, .Home.Supervisor .buttonWrapper{
    /* background-color: yellow; */
    width: 90vw;
    grid-template-columns: repeat(4, 1fr);
    align-items: stretch;
    height: fit-content;
    margin: auto;
    display: grid;
    gap: 24px;
}

 .Home .buttonWrapper.caisse{
    grid-template-columns: repeat(3, 1fr);
}


.Home .buttonWrapper.retours{
    grid-template-columns: repeat(4, 1fr);
}

.Home .buttonWrapper.planning{
    grid-template-columns: repeat(4, 1fr);
}

.Home .buttonWrapper.planning>a:first-child{
    grid-column: 2 / 3;
}

.Home .buttonWrapper>.vente{
    grid-column: 1 / 4;
}

.Home .buttonWrapper>.loc{
    grid-column: 4 / 5;
}

.Home .buttonWrapper.retours .previsions {
    grid-column: 2 / 2;
}

.homeBackButton{
    display: flex;
    justify-content: end;
    padding: 0 1em;

}

.homeBackButton svg{
    width: 40px;
    height: 40px;
    color: var(--main-color);
}


.Home .buttonWrapper a {
    background: var(--main-color);
    font-weight: 600;
    height: 150px;
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    color: #FFF;
    padding: 0.2em;

}

.Home a.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}

.Home a span{
    margin-top: 0.5em;
    text-align: center;
}


.Home a svg{
    width: 60px;
    height: 60px;
}

.Home button.back {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
}

.ModalNouveautes .content {
    padding: 0 1em;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ModalNouveautes .content h2 {
    text-align: center;
    color: var(--main-color);
    margin-bottom: 3em;
}

.ModalNouveautes .content h3 {
    color: var(--main-color-3);
}

.ModalNouveautes .content li {
    margin: 1em 0;
    list-style-type: none;
    display: flex;
}

.ModalNouveautes .content li svg{
    color: var(--main-color);
    width: 16px;
    margin-right: 1em;
}

.ModalNouveautes .footer {
    height: fit-content;
}

.Home.Supervisor .produits span:after {
    content: 'Gérer les produits';
}

.Home.Supervisor .prix span:after {
    content: 'Modifier les tarifs de location';
}

.Home.Supervisor .shops span:after {
    content: 'Administrer les magasins';
}

.Home.Supervisor .aide span:after {
    content: 'Consulter les statistiques de l\'aide en ligne';
}

.Home a span:not(.short) {
    display: inline;
}

.Home a span.short {
    display: none;
}

@media screen and (max-width: 640px) {

    .Home .buttonWrapper{
        grid-template-columns: repeat(3, 1fr);
    }

    .Home .buttonWrapper.retours{
        grid-template-columns: repeat(3, 1fr);
    }

    .Home .buttonWrapper .vente{
        grid-column: 1 / 4;
    }

    .Home .buttonWrapper.planning{
        grid-template-columns: repeat(2, 1fr);
    }

    .Home .buttonWrapper.planning>a:first-child{
        grid-column: 1 / 2;
    }

    .Home .buttonWrapper .loc{
        grid-column: 1 / 1;
    }

    .Home a svg{
        width: 40px;
        height: 40px;
    }
       
    .Home .buttonWrapper a {
        font-size: 13px;
        height: 100px;
    }


    .Home.Supervisor .produits span:after {
        content: 'Produits';
    }

    .Home.Supervisor .prix span:after {
        content: 'Tarifs';
    }

    .Home.Supervisor .shops span:after {
        content: 'Statistiques';
    }

    .Home.Supervisor .aide span:after {
        content: 'Aide en ligne';
    }

    .Home a span:not(.short) {
        display: none;
    }
    
    .Home a span.short {
        display: inline;
    }
}
    