.AideConsultationDetail {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content 1fr;
}

.AideConsultationDetail .AideConsultationChartBlock>div:first-child {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin: 0.5em 0;
}

.AideConsultationDetail .AideConsultationChartBlock button.selected {
    opacity: 0.5;
}

.AideConsultationDetail .AideConsultationChartBlock button svg {
    height: 20px;
    width: 20px;
    color: var(--text-color);
}