.CorrectionCaisse td.paiement {
    cursor: pointer;
}

.CorrectionCaisse td.paiement:hover {
    opacity: 0.5;
}

.CorrectionCaisse td.paiement:hover {
    opacity: 0.5;
}

.CorrectionCaisse table {
    table-layout: fixed;
}

.CorrectionCaisse table tbody tr{
    height: 49px;
}


.CorrectionCaisse table th:nth-child(1){
    width: 10%;
}

.CorrectionCaisse table th:nth-child(3), .CorrectionCaisse table th:nth-child(4){
    width: 20%;
}

.CorrectionCaisse td .select{
    height: 40px;
}

.CorrectionCaisse td select{
    height: 40px;
}

.CorrectionCaisse .filtresColumn{
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.CorrectionCaisse .filtres button{
    margin: 2em auto;
}

.CorrectionCaisse .ListeActions span:not(.short) {
    display: inline;
}

.CorrectionCaisse .ListeActions span.short {
    display: none;
}


@media screen and (max-width: 640px) {
    .CorrectionCaisse .filtresColumn:first-child{
        display: grid;
        grid-template-columns: 1fr 1fr ;
    }

    .CorrectionCaisse .filtresColumn:first-child h3{
        grid-column: 1 / -1;
    }

    .CorrectionCaisse .filtresColumn:first-child span{
        display: none;
    }

    .CorrectionCaisse table th:nth-child(1){
        width: 45%;
    }

    .CorrectionCaisse .ListeActions .code {
        display: none;
    }

    .CorrectionCaisse .ListeActions span:not(.short) {
        display: none;
    }
    
    .CorrectionCaisse .ListeActions span.short {
        display: inline;
    }
    
}