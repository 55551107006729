.Settings .theme>div{
    display: flex;

}

.Settings .themePicker {
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    box-shadow: var(--button-shadow);
    margin: 0 1.5em;
}

.Settings .themePicker:hover{
    opacity: 0.5;
}

.Settings .themePicker.selected {
    border: 2px solid var(--text-color);
}