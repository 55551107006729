.BarChart>div:first-child{
    font-weight: 600;
    margin-bottom: 0.2em;
    text-align: center;
}

.BarChart>div:nth-child(2){
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
}

.BarChart span{
    margin: 0 0.5em;
}

