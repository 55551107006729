.ligneShop{
    cursor: pointer;
    margin: 1em;
    padding: 1.25em;
    background-color: var(--background-color-2);
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content max-content;
    position: relative;
}

.ligneShop>div:first-child {
    display: flex;
    width: 100%;
    align-items: baseline;
}

.ligneShop.all>div:first-child {
    grid-row: 1 / 3;
    
}

.ligneShop>div:nth-child(2) {
    grid-column: 2 / 2;
    grid-row: 1 / 3;
    display: flex;
    align-items: center;
}

.ligneShop>div:nth-child(3) {
    display: flex;
    width: 100%;
    padding-top: 0.1em;
}



.ligneShop.connected:not(.all):hover{
    background-color: var(--background-color-3);
}

.ligneShop:not(.connected):not(.all) {
    opacity: 0.7;
    cursor: default;
}

.ligneShop .dot, .ligneShop .loaderContainer {
    border-radius: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 1.25em;
    width: 15px;
    height: 15px;
}

.ligneShop .dot.green{
    background-color: var(--success-color);
}

.ligneShop .dot.red{
    background-color: var(--error-color);
}

.ligneShop button {
    padding: 0;
    color: var(--text-color);
    margin: auto 0;
    margin-right: 1.25em;
    width: 18px;
    height: 18px;
}

.ligneShop>div:first-child button {
    width: 15px;
    height: 15px;
}

.ligneShop>div:nth-child(3) button:first-child{
    margin-left: 35px;
}


.ligneShop .shopName{
    margin: auto 0;
    font-size: 20px;
    font-weight: 600;
}

.ligneShop .chiffreAffaires td{
    width: 120px;
    text-align: center;
    
}

.ligneShop th {
    font-weight: 400;
    color: var(--main-color)
}

.ligneShop .caEx{
    margin-left: auto;
    font-size: 20px;
    font-weight: 600;
}

.ligneShop .ca30{
    margin-left: auto;
    font-size: 18px;
    font-weight: 500;
}

.ligneShop .ca7{
    margin-left: auto;
    font-size: 16px;
}

.ligneShop .ca{
    margin-left: auto;
    font-size: 14px;
}

.ligneShop .N-1{
    opacity: 0.7;
}

.ligneShop .timestamp {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 12px;
    color: var(--main-color);
}

.detailShop {
    padding: 0.5em;
    margin: 1em;
}

.detailShop>.oneColumn {
    display: none;
}

.detailShop> .Block:first-child {
    margin-bottom: 1em;
}

@media screen and (max-width: 780px) {

    .ligneShop{
        margin: 0.5em
    }

    .ligneShop thead {
        display: none;
    }

    .ligneShop .N-1{
        display: none;
    }

    .ligneShop tbody, .ligneShop table {
        display: flex;
        flex-direction: column;
    }

    .ligneShop td {
        display: flex;
        flex-direction: column;
    }

    .ligneShop .dot {
        visibility: hidden;
    }

    .detailShop{
        margin: 0em;
        padding: 0.5em;
    }

    .detailShop .oneColumn {
        grid-template-columns: 1fr;
        align-items: stretch;
        display: grid;
        gap: 24px;
        margin: 0.5em 0;
    }

    .detailShop .Block{
        margin-right: 0;
        margin-left: 0;
        width: 100%;
    }

    .detailShop>*:not(.oneColumn):not(:first-child) {
        display: none;
    }
}