.fond {
    width: 50vw;
    object-fit: cover;
    height: 100vh;
    position: absolute;
    top:0;
    left:0;
}

.loginForm{
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5em 0.75em;
    background-color: #fff;
}

.logo{
    width: 50%;
    margin: 0 auto;
}

.loginForm>h1{
    margin: 1em auto;
    color: var(--main-color);
    padding: 0;
    padding-bottom: 1.5em;
}

.loginForm .input {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.loginForm .buttonContainer{
    padding: 5.5em 0;
    margin-left: auto;
    margin-right: auto;
}

.loginForm .error {
    border-radius: 10px;
    margin: 2em auto;
    width: 50%;
    /* font-size: 15px; */
    text-align: center;
    color: var(--error-color);
}


.loginFooter {
    position: absolute;
    bottom: 0.5em;
    left: 0.5em;
    color: var(--text-color);
    font-size: 12px;
}


@media screen and (max-width: 640px) {  
    .fond{
        display: none;
    }

    .loginForm{
        width: 100vw;
    }

    .logo{
        width: 90%;
    }

    .loginForm .input, .loginForm .error{
        width: 80%;
    }
}