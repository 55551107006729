:root{
  --background-color : rgba(222, 233, 245, 0.384);
  /* --background-color-2 : rgba(183, 211, 240, 0.384);
  --background-color-3 : rgba(222, 234, 248, 0.384); */
  --bg-overlay : #ffffff81;

  --title-color : rgb(68, 88, 109);
  --title-color-2 : rgb(82, 103, 126);
  --text-color:rgb(84, 110, 136);
  --text-color-light:rgb(149, 178, 207);

  --no-allotement:rgb(107 114 128);
  --allotement-surbooking:rgb(172, 46, 46);
  --allotement-red:rgb(239 68 68);
  --allotement-orange:rgb(249 115 22);
  --allotement-yellow:rgb(234 179 8);
  --allotement-green:rgb(34 197 94);

  --error-color : #ec2247;
  --error-bg-color : #f5aeae;
  --warning-color : #df8908;
  --warning-bg-color : #fae698;
  --info-color : #00529b;
  --info-bg-color: #bde5f8;
  --success-color : #7cb93a;
  --success-bg-color : #d0f58f;


  --statistiques-bg-color: #4cbad21c;
  --swiper-navigation-size : 15px;

  /* --error-color:rgb(241, 155, 143); */
  --disabled-color :rgb(180, 202, 224);

  --block-shadow : rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;;

  --button-shadow : rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  --button-shadow-2 : rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;;

  --font : "Montserrat",sans-serif;
}

:root{
  --swiper-navigation-size: 44px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-navigation-color: var(--swiper-theme-color);
}

/*------------------- general ----------------------*/


body * {
  box-sizing: border-box;
}

body, html{
  min-height: 100vh;
  font-family: var(--font);
  font-size : 16px;
}

button{
  font-size: inherit;
  border: none;
  background-color: transparent;
}

button:not(:disabled), a:not(:disabled){
  cursor: pointer;
}

button:not(:disabled):hover, a:not(:disabled):hover{
  opacity: 0.5;
}


button:disabled{
  cursor: not-allowed;
  background-color: var(--disabled-color);

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a{
  text-decoration: none;
}

h1{
  font-size: 30px;
}

h1 svg {
  width: 30px;
  height: 30px;
}

h2{
  padding-bottom: 0.2em;
  font-size: 24px;
}

h2 svg{
  width: 30px;
  height: 30px;
}

.flex{
  display: flex;
}

#root{
  height: 100%;
}

.container  {
  position: relative;
  min-height: 100vh;
  background-color: var(--background-color);

}


.container>:not(.errorContainer):not(.Home), .Admin>div:nth-child(2){
  min-height: 100vh;
  display: grid;
  padding: 1em;
  grid-auto-rows:auto;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  width: 100%;
}

@media screen and (max-width: 640px) {
    h1{
      font-size: 23px;
    }
    
    h1 svg {
      width: 23px;
      height: 23px;
    }

    h2{
      font-size: 18px;
    }

    h2 svg{
      width: 20px;
      height: 20px;
    }

    .container>:not(.errorContainer){
      padding: 0.5em;
      grid-column-gap: 0.5em;
      grid-row-gap: 0.5em;
    }  
}  

.overlay{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-overlay);
}
 

/*------------------- fiche ----------------------*/

.Fiche{
  box-sizing: border-box;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: max-content max-content max-content ;
}

.Fiche .Block:not(:last-child), .Fiche .Title {
  grid-column: 1 / 1;
}

.Fiche>div:last-child{
  grid-column: 2 / 2;
  grid-row: 1 / -1;
}

@media screen and (max-width: 640px) {  
  .Fiche{
    grid-template-columns: 1fr;
  }

  .Fiche>div:last-child{
    grid-column: 1 / 1;
    grid-row: auto;
  }
}

/*---------------- caisse et retours ------------------*/

.Caisse, .RetoursAnticipes, .CorrectionCaisse, .RemisesEnBanque {
  grid-template-columns: 1fr 4fr ;
  grid-template-rows: max-content 1fr ;
}

.EtatStocks {
  grid-template-columns: 1fr 4fr ;
  grid-template-rows: max-content max-content 1fr ;
}

.EtatStocks>.Block:nth-child(2){
  grid-row: 2 / 3;
}

.EtatStocks>.Block:nth-child(3){
  grid-row: 2 / 4;
}

/* .RemisesEnBanque>.Block:nth-child(3) {
  grid-row: 2 / 4;
  grid-column: 2 / 2;
} */



@media screen and (max-width: 640px) {  
  .Caisse, .EtatStocks, .RemisesEnBanque, .RetoursAnticipes, .CorrectionCaisse {
    grid-template-rows: max-content max-content 1fr ;
    grid-template-columns: 1fr;
  }

  .EtatStocks>.Block:nth-child(2){
    grid-row: 2/3;
  }
  
  .EtatStocks>.Block:nth-child(3){
    grid-row: 3 / 3;
  }
/* 
  .RemisesEnBanque>.Block:nth-child(2){
    grid-row: 3 / 3;
  } */

}

/*---------------------- planning ----------------------*/

.Planning, .EntreeStock, .SupervisorStatistiques, .Settings{
  grid-template-rows: max-content 1fr ;
}


.Planning .Block iframe{
  overflow: visible;
}

/*------------------- statistiques ----------------------*/

.Statistiques{
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content 1fr ;
}

.ShopStatistiques{
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
}

.ShopStatistiques .Block{
  padding: 0.5em;
}

@media screen and (max-width: 640px) {  
  .Statistiques{
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr ;
  }
}

/*------------------ gestion de produits -------------------*/

.GestionProduits, .ReductionAllotement, .Allotements, .GestionTarifsLocation{
  grid-template-columns: 3fr 7fr;
  grid-template-rows: max-content 1fr ;
}


@media screen and (max-width: 640px) {  
  .GestionProduits, .Allotements, .GestionTarifsLocation, .ReductionAllotement{
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content 1fr ;
  }
}


/*------------------ etats des stocks ---------------------*/

.EntreeStock .ButtonList{
  margin-top: 2em;
}

/*------------------ sorties/retours ---------------------*/

.Previsions .retours {
  grid-row: 3 / 3;
}

.Previsions>.Block:nth-child(4) {
  grid-row: 2 / 4;
  grid-column: 2 / 3;
}

.Previsions {
  grid-template-columns: 1fr 4fr ;
  grid-template-rows: max-content 1fr 1fr ;
}

@media screen and (max-width: 640px) {
  .Previsions {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content max-content 1fr ;
  }

  .Previsions>.Block.retours {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  .Previsions>.Block:last-child {
    grid-row: 3 / 4;
    grid-column: 1 / 3;
  }



}  
