
.Block.Reglement{
    position: sticky;
    top: 1em;
}

.total{
    margin-top: 2em;
    /* padding-bottom: 1em; */
    display: flex;
    color: var(--title-color);
}

.subTotal{
    margin-top: 2em;
    color: var(--text-color);
    display: flex;
}

.subTotal span:first-child{
    font-weight: 600;
}


.subTotalInfo{
    display: flex;
    /* font-size: 14px; */
    color: var(--text-color-light);
    margin-left: 2em;
}


.subTotal>span:nth-child(2), .total>span:nth-child(2), .subTotalInfo>span:nth-child(2){
    margin: auto 0;
    margin-left: auto;
}

.totalBase{
    padding-right: 1em;
    text-decoration: line-through;
    /* font-size: 14px; */
    color: var(--text-color-light);
}

.lignePaiement{
    margin-top: 0.3em;
    color: var(--text-color);
    display: flex;
    position: relative;
	animation: append-animate .2s linear;
}

@keyframes append-animate {
	from {
		transform: scale(0);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;	
	}
}

.lignePaiement>span{
    font-style: italic;
}

.lignePaiement>*:first-child {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.lignePaiement span:nth-child(3){
    margin-left: auto;
}

.lignePaiement>button{
    padding: 0;
}

.lignePaiement svg {
    width: 15px;
    height: 15px;
    color: var(--text-color);
}

.Block.Reglement .ButtonList{
    margin-top: 0.5em;
    padding-top: 0.5em;
}


.Block.Reglement .ButtonList.PaiementReservation {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
}

.refreshButton svg {
    color: var(--text-color);
    width: 25px;
    height: 25px;
}

.montantManuel {
    display: flex;
    justify-content: center;
}

.montantManuel * {
    margin-top: auto;
    margin-bottom: auto;
}

.Reglement .paiements{
    padding-bottom: 1em;
    position: relative;
}


.resteAPayer{
    border-bottom: 1px solid var(--text-color);
    padding-bottom: 0.5em;

}

.options{
    border-top: 1px solid var(--text-color);
    padding-top: 1em;
    padding-bottom: 0.5em;

}
