.RemisesEnBanque .Block.filtres {
    display: flex;
    flex-direction: column;
}

.RemisesEnBanque .Block.filtres .VideoLink.small {
    margin-left: auto;
    margin-top: 0.5em;
    width: fit-content;
}

.RemisesEnBanque .ligneRemise:hover {
    cursor: pointer;
    opacity: 0.5;
}


.RemisesEnBanque .ligneRemise.selected {
    opacity: 0.5;
}

.CreationCodeComptable, .CreationRemiseEnBanque {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.CreationRemiseEnBanque>div:not(.modal-container), .CreationCodeComptable>div {
    display: grid;
    column-gap: 0.75em;
    row-gap: 0.75em;
    padding: 0.5em;
    grid-template-columns: repeat(10, 1fr);
    padding-left: 1em;
    height: 100%;
}

.CreationRemiseEnBanque .frameContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.CreationRemiseEnBanque iframe {
    width: 100%;
    height: 100%;
}

.CreationRemiseEnBanque>div{
    grid-template-rows: repeat(4, max-content) 1fr;
}

.CreationRemiseEnBanque .date {
    text-align: start;
}

.CreationRemiseEnBanque .date, .CreationRemiseEnBanque .type {
    grid-column: 1 / 6;
}

.CreationRemiseEnBanque .code {
    grid-column: 6 / 10;
}

.CreationRemiseEnBanque .montant {
    grid-column: 1 / 4;
}

.CreationRemiseEnBanque .codePaiement {
    grid-column: 4 / 8;
}

.CreationRemiseEnBanque .commentaire {
    width: 100%;
    grid-column: 1 / 10;
}

.CreationRemiseEnBanque .submit {
    grid-column: 1 / 11;
    display: flex;
    justify-content: end;
    align-items: end;
}

.CreationCodeComptable>div {
    grid-template-rows: max-content max-content 1fr;
}

.CreationCodeComptable h2 {
    display: flex;
    justify-content: space-between;
}

.CreationCodeComptable .libelle{
    grid-column: 1 / 6;
}

.CreationCodeComptable .code {
    grid-column: 1 / 4;
}

.CreationCodeComptable .submit {
    grid-column: 1 / 11;
    display: flex;
    justify-content: end;
    align-items: end;
}



@media screen and (max-width: 640px) {
    .CreationRemiseEnBanque .date, .CreationRemiseEnBanque .type {
        grid-column: 1 / 10;
    }
    
    .CreationRemiseEnBanque .code {
        grid-column: 1 / 6;
    }

    .CreationRemiseEnBanque .montant {
        grid-column: 6 / 10;
    }

    .CreationRemiseEnBanque .codePaiement {
        grid-column: 1 / 10;
    }

    .CreationCodeComptable .libelle{
        grid-column: 1 / 10;
    }

    .CreationCodeComptable .code {
        grid-column: 1 / 7;
    }

}