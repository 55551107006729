.standardButton{
    box-shadow: var(--button-shadow-2);
    background-color: var(--main-color);
    /* font-size: 16px; */
    width: fit-content;
    color: white!important;
    padding: 0.5em 1.5em;
    border-radius: 10px;
}

.underlinedButton {
    display: inline-flex;
    align-items: flex-end; 
    text-decoration: underline;
    font-weight: 500;
    width: fit-content;
    color: var(--main-color);
}