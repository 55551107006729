.Admin:has(> .User) {
    height: 100vh;
}

.Supervisor .form {
    grid-template-columns: repeat(6, 1fr);
}

.User .form .id{
    grid-column: 1 / 1;
}

.User .form .role{
    grid-column: 2 / 4;
}

.User .form .username {
    grid-column: 1 / 3;
}

.User .form .theme {
    grid-column: 3 / 5;
}

.User .form .email{
    grid-column: 1 / 4;
}

.User .form .password, .User .form .magasin{
    grid-column: 1 / 5;
}

.User .form .check{
    height: 50px;
    display: flex;
    align-items: center;
}


.User .form .check>svg {
    width: 25px;
    color: var(--success-color);
}

.User .SupervisorShopStats>div {
    overflow-y: auto;
}


.User .SupervisorShopStats thead {
    position: sticky;
    top: 0;
    z-index: 5;
}
