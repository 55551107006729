.ClientLocation .form{
    display: flex;
}

.ClientLocation .form>div{
    display: flex;
    flex-direction: column;
    /* padding: 0 0.5em; */
    width: 50%;
}

.ClientLocation .form>div:first-child{
    display: grid;
    column-gap: 0.5em;
    padding: 0.5em;
    grid-template-columns: repeat(20, 1fr);
}

.ClientLocation .form .nom{
    grid-column: 1 / 12;
}

.ClientLocation .form .prenom {
    grid-column: 12 / 21;
}

.ClientLocation .form .nc{
    grid-column: 1 / 13;
}

.ClientLocation .form .date{
    grid-column: 13 / 18;
}

.ClientLocation .form .codeControle{
    grid-column: 18 / 21;
}

.ClientLocation .form .emplacement{
    grid-column: 1 / 8;
}

.ClientLocation .form .telephone{
    grid-column: 1 / 12;
}

.ClientLocation .form .input{
    margin-bottom: 0;
}

.ClientLocation .form textarea{
    width: 100%;
    margin: 0.5em auto;
}

.ClientLocation .form button:first-child, .form .loaderContainer{
    margin-left: auto;
}


.ClientLocation .form button:nth-child(2){
    margin-left: 2em;
}

.ClientLocation .form .btnResa {
    position: relative;
    grid-column: 13 / 18;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1em;
}

.ClientLocation .form .btnResa .VideoLink.small {
    margin-left: 1em;
}

.ClientLocation .modalReservations .filtres {
    display: flex;
}

.ClientLocation .modalReservations .table {
    table-layout: fixed;
    font-size: 16px;
}

.ClientLocation .modalReservations .table input {
    text-align: start;
}

.ClientLocation .modalReservations .table th span {
    cursor: pointer;
}

.ClientLocation .modalReservations .table th span:hover, .ClientLocation .modalReservations .table th span.selected  {
    opacity: 0.5;
}

.ClientLocation .modalReservations .table tbody tr  {
    cursor: pointer;
}

.ClientLocation .modalReservations .table tbody tr:hover  {
    opacity: 0.5;
}

.ClientLocation .modalReservations th.empl {
    width: 8%;
}

.ClientLocation .modalReservations th.empl::after {
    content: "Emplacement";
}

@media screen and (max-width: 640px) {
    .ClientLocation .form{
        display: block;
    }

    .ClientLocation .form>div{
        width: 100%;
    }

    .ClientLocation .form .date{
        grid-column: 13 / 21;
    }

    .ClientLocation .form .codeControle{
        grid-column: 1 / 6;
    }
    
    .ClientLocation .form .emplacement{
        grid-column: 6 / 14;
    }

    .ClientLocation .form .emplacement label::before{
        content: "Empl."!important;
    }

    .ClientLocation .form .telephone{
        grid-column: 1 / 14;
    }

    .ClientLocation .form button, .form .loaderContainer{
        margin: auto;
    }

    .ClientLocation .form .btnResa {
        justify-content: start;
        grid-column: 1 / 21;
    }

    .ClientLocation .form .btnResa button {
        margin-left: 0;
    }

    .ClientLocation .modalReservations th.id,
    .ClientLocation .modalReservations th.deb,
    .ClientLocation .modalReservations th.fin,
    .ClientLocation .modalReservations th.jr,
    .ClientLocation .modalReservations td.id,
    .ClientLocation .modalReservations td.deb,
    .ClientLocation .modalReservations td.fin,
    .ClientLocation .modalReservations td.jr {
        display: none;
    }

    .ClientLocation .modalReservations th.empl {
        width: 20%;
    }

    .ClientLocation .modalReservations th.empl::after {
        content: "Empl.";
    }


    
    
}