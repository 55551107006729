/** ----------------------- TEXT INPUT ----------------------- **/

.input input {
    box-sizing: border-box;
    width: 100%;
    height: calc(3em + 2px);
    margin: 0 0 1em;
    padding: 1em;
    border: 1px solid var(--text-color-light);
    border-radius: 10px;
    background: #fff;
    resize: none;
    outline: none;
    color: var(--text-color);
}

.input.locked input {
  background-color: var(--background-color);
  border: none;
  color: var(--text-color-light)
}

.input.locked input {
  pointer-events: none;
}

.input:not(.locked) input:focus {
    border-color: var(--main-color);
}

.input input:focus + label[placeholder]:before {
    color: var(--main-color);
}

.input input:not(:placeholder-shown) + label[placeholder]:before, 
.input input:focus + label[placeholder]:before{
    transition-duration: .2s;
    transform: translate(0,-1.5em) scale(.9,.9);
}

.input input:invalid + label[placeholder][alt]:before {
    content: attr(alt);
}

.input input:invalid  {
  border-color: var(--error-color);
}

.input input + label[placeholder] {
    display: block;
    pointer-events: none;
    line-height: 1.25em;
    margin-top: calc(-3em - 2px);
    margin-bottom: calc((3em - 1em) + 2px);
    color: var(--text-color-light);
}

.input input + label[placeholder]:before {
    content: attr(placeholder);
    display: inline-block;
    margin: 0 calc(1em + 2px);
    padding: 0 2px;
    color: inherit;
    white-space: nowrap;
    transition: .3s ease-in-out;
    background-image: linear-gradient(to bottom,#fff,#fff);
    background-size: 100% 5px;
    background-repeat: no-repeat;
    background-position: center;
}

.input input {
    font-size: 100%;
}

/** ----------------------- PASSWORD ----------------------- **/

.input{
  color: var(--text-color-light);
  position: relative;
}

.input.password button{
  padding: 0;
  color: inherit;
  position: absolute;
  top:13px;
  right: 0.5em;
  z-index: 10;
}

.input.password svg {
  width: 24px;
  height: 24px;
}

/** ------------------------- DATETIME ----------------------- **/

.dateTime fieldset{
  border: none;
}

.dateTime div{
  margin: 0!important;
  padding: 0!important;
  width: fit-content;

}

.dateTime input{
  height: 50px;
  box-sizing: border-box;
  padding: 1.25em;
  border: 1px solid var(--text-color-light);
  border-radius: 10px;
  resize: none;
  outline: none;
  color: var(--text-color);
}

.dateTime input:focus {
  border-color: var(--main-color);
}

.dateTime button{
  position: absolute;
  margin: 0;
  right: 0;
  color: var(--text-color);
  border-radius: 0;
}

.dateTime label {
  margin-left: 0.5em;
  padding-left: 0;
  background-color: #FFF;
  color: var(--text-color-light);
  font-size: 18px;

}

@media screen and (max-width: 640px) {
  .dateTime label {
    font-size: 16px;
  }
}

/** ----------------------- TEXT AREA ----------------------- **/

.textareaInput{
    font-family:  var(--font);
    outline:none;
    display: block;
    font-size: 16px;
    color: var(--text-color);
    width: 100%;
    height: 230px;
    border-radius: 10px;
    border: 1px solid var(--text-color-light);
    padding: 0.5em;
    resize: none;
    margin-bottom: 1em;
    margin-left: auto;
    
}

.textareaInput:focus {
  border-color: var(--main-color);
}

/** ------------------- SELECT DROPDOWN --------------------- **/

.select select {
  box-sizing: border-box;
  width: 100%;
  height: calc(3em + 2px);
  margin: 0 0 1em;
  padding: 0 1em;
  border: 1px solid var(--text-color-light);
  border-radius: 10px;
  background: #fff;
  resize: none;
  outline: none;
  color: var(--text-color);
}

.select select:not(:placeholder-shown) + label[placeholder]:before, 
.select select:focus + label[placeholder]:before {
  transition-duration: .2s;
  transform: translate(0,-1.5em) scale(.9,.9);
}

.select select + label[placeholder] {
  display: block;
  pointer-events: none;
  line-height: 1.25em;
  margin-top: calc(-3em - 2px);
  margin-bottom: calc((3em - 1em) + 2px);
  color: var(--text-color-light);
}
.select select + label[placeholder]:before {
  content: attr(placeholder);
  display: inline-block;
  margin: 0 calc(1em + 2px);
  padding: 0 2px;
  color: inherit;
  white-space: nowrap;
  transition: .3s ease-in-out;
  background-image: linear-gradient(to bottom,#fff,#fff);
  background-size: 100% 5px;
  background-repeat: no-repeat;
  background-position: center;
}

.select select{
  font-size: 100%;
}

.select select:invalid  {
  border-color: var(--error-color);
}


/** --------------------- TOGGLE SWITCH --------------------- **/

.switchContainer{
    margin: 0.5em;
    display: flex;
}

.switchLabel{
    margin-top: auto;
    margin-bottom: auto;
}

 .switch {
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: auto;
    margin-bottom: auto;
    scale: 0.5;
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--text-color-light);
    opacity: 0.5;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    opacity: 1;
    background-color: var(--main-color);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  } 


  /** ------------------- SIMPLE TEXT INPUT --------------------- **/

  .simpleInput input{ 
    box-sizing: border-box;
    width: 100%;  
    border: none;
    padding: 0.25em;
    margin: 0.25em;
    color: var(--text-color);
    font-family: inherit;
    border-bottom: 2px solid var(--text-color-light);
    text-align: center;   
    background-color: transparent; 
  }

  .simpleInput input:focus{
    outline: none;  
    font-weight: 700;
    border-bottom: 2px solid var(--text-color);
  }