.ListePacks {
    height: 100%;
    overflow-y: scroll;
}

.ListePacks>div {
    display: flex;
    flex-direction: column;
}

.ListePacks button{
    list-style-type: none;
    font-style: inherit;
    font-size: inherit;
    color: var(--text-color);
    cursor: pointer;
    margin: 0.3em;
    width: fit-content;
}

.ListePacks button.selected{
    opacity: 0.5;
}

.ListePacks .select {
    display: none;
}

@media screen and (max-width: 640px) {
    .ListePacks button {
        display: none;
    }

    .ListePacks .select {
        display: block;
    }
}
