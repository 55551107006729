
/*--------------- block ---------------*/

.Block {
    background-color: white;
    box-shadow: var(--block-shadow);
    transition: max-height 1s;
    position: relative;
    padding : 0.5em 0.5em 2em 0.5em;
    color: var(--text-color);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.Block>div, .Block>form{
    animation: open-animate .2s linear;
}

@keyframes open-animate {
	from {
        max-height: 0;
        transition: max-height ;
	}
	to {
        max-height: 500px;
	}
}

@keyframes close-animate {
	from {
        max-height: 500px;
        transition: max-height ;
	}
	to {
        max-height: 0;
	}
}

/*--------------- blocktitle ---------------*/

.close>svg{
    rotate: 0deg;
    transition: rotate 300ms;
}

.open>svg{
    rotate: 180deg;
    transition: rotate 300ms;
}

/*--------------- vente et location ---------------*/

.ButtonList{
    text-align: center;
}

.lockedButtons button{
    opacity: 0.4;
   pointer-events: none;
}


/*--------------- partie location ---------------*/

.contentLocation .inputs{
    display: flex;
    height: 42px;
    justify-content: center;
    margin-bottom: 1.5em;
}

.contentLocation .inputs>div{
    display: flex;
    justify-content: center;
}


.contentLocation .inputs div>*{
    margin: auto 0.3em;
}

.contentLocation .inputs .editable{
    font-weight: 700;
    color: var(--title-color-2);

}




@media screen and (max-width: 640px) {
    .contentLocation .inputs{
        margin-bottom: 5em;
        display: block;
    }


    .contentLocation .debut, .contentLocation .duree{
        margin-bottom: 1em;
        justify-content: center;

    } 

    .contentLocation .debut {
        height: 42px;
    }

}