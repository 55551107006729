.modalTicket .modalTicketFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.modalTicket .input {
  margin: 1em 0!important;
}

.modalTicket .input input:placeholder-shown{
  border-color: var(--error-color);
}

.modalTicket .error {
  color: var(--error-color);
}

.modalTicket .loaderContainer {
  left: 0;
  width: 100%;
  position: absolute;
  bottom: 1em;
}


.modalTicket .modalTicketFooter>* {
    margin: auto 0;
}


.modalVoucher .frameContainer {
  width: 100%;
  height: 100%;
}

.modalVoucher .frameContainer iframe{
  border: none;
  width: 100%;
  height: 100%;
}

.sortButtons {
  margin-top: 1em;
  text-align: end;
}

.sortButtons button {
  color: inherit;
}


.Panier {
  margin-top: 1.5em;
  border-collapse: separate;
  border-spacing: 0 1em;
  width: 100%;
  table-layout: fixed;

}

.Panier th{
  font-size: 15px;
  padding: 0.5em 0;
  font-weight: 700;
  background-color: var(--background-color-2);
  color: var(--text-color)
}

.Panier td{
  font-size: 14px;
  text-align: center;
  background-color: var(--background-color-3);
  color: var(--text-color);
  padding: 1em 0;
}

.LignePanier {
  position: relative;
  animation: append-animate .2s linear;
}

@keyframes append-animate {
from {
  transform: scale(0);
  opacity: 0;
}
to {
  transform: scale(1);
  opacity: 1;	
}
}


.Panier .editable{
  font-weight: 700;
  color: var(--title-color-2);
}


.LignePanier svg {
  width: 18px;
  height: 18px;
}

.LignePanier button{
  color: var(--title-color-2);
}


.LignePanier .overlay{
  background-color: var(--bg-overlay);
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.LignePanier .overlay>*{
  margin: auto;
  color: var(--text-color);
}


.Panier.Location th.categorie {
  width: 20%;
}

.Panier.Location th.debut,
.Panier.Location th.fin {
  width: 15%;
}

.Panier.Location th.suppr, 
.Panier.Vente th.suppr {
  width: 5%;
}

.Panier.Vente th.lib {
  width: 20%;
}

@media screen and (max-width: 640px) { 
  .sortButtons {
    display: none;
  }

  .Panier td{
    font-size: 13px;
  }

  .LignePanier svg {
      width: 13px;
      height: 13px;
  }

  .Panier.Location .code, 
  .Panier.Location .fin,
  .Panier.Location .mnt  {
    width: 0%!important;
  }

  .Panier.Location .code>*, 
  .Panier.Location .fin>*,
  .Panier.Location .mnt>*  {
    display: none;
  }

  .Panier.Location th.debut {
    width: 25%;
  }

  .Panier.Location th.suppr,
  .Panier.Vente th.suppr {
    width: 10%;
  }

  .Panier.Location th.categorie span {
    display: none;
  }

  .Panier.Location th.categorie:after {
    content:"Cat."
  }

  .Panier.Location th.aj span {
    display: none;
  }

  .Panier.Location th.aj:after {
    content:"Aj."
  }

  .Panier.Vente .code,
  .Panier.Vente .stk,
  .Panier.Vente .pu {
    width: 0%!important;
  }

  .Panier.Vente .code>*,
  .Panier.Vente .stk>*,
  .Panier.Vente .pu>* {
    display: none;
  }

  .Panier.Vente th.lib span,
  .Panier.Vente th.coul span,
  .Panier.Vente th.qte span,
  .Panier.Vente th.rem span {
    display: none;
  }

  .Panier.Vente th.lib:after {
    content:"Lib."
  }

  .Panier.Vente th.coul:after {
    content:"Coul."
  }

  .Panier.Vente th.qte:after {
    content:"Qté."
  }

  .Panier.Vente th.rem:after {
    content:"Rem."
  }


}