.AideConsultation .buttonsContainer {
    display: flex;
    justify-content: center;
    margin: 0.5em 0;
}

.AideConsultation .buttonsContainer button.selected {
    opacity: 0.5;
}

.AideConsultation .buttonsContainer svg {
    height: 20px;
    width: 20px;
    color: var(--main-color);
}

.AideConsultation .chartContainer {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 0.25em;
}