.Shop .form .id {
    grid-column: 1 / 2;
}

.Shop .form .name {
    grid-column: 1 / 4;
}

.Shop .form .idBase {
    grid-column: 1 / 5;
}

.Shop .form .codeCaisse {
    grid-column: 1 / 2;
}

.Shop .form .idLot {
    grid-column: 1 / 4;
}


