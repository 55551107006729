.LigneGrillePrix td {
    padding: 0.3em 0.7em;
    text-align: start!important;
    cursor: pointer;
}

.LigneGrillePrix:hover, .LigneGrillePrix.selected {
    opacity: 0.5;
}

.formGrillePrix {
    display: flex;
    flex-direction: column;
}

.formGrillePrix input{
    width: 50px;
}

.formGrillePrix .standardButton {
    margin: 1em auto;
}

.lignePrix td:nth-child(2) div {
    text-align: center;
}

.lignePrix td:nth-child(2)>div {
    display: inline-flex;
    justify-content: center;
    align-items: center;

}

.editGrillePrix .buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 2em;
}

.editGrillePrix .buttons>button {
    margin: 0 1em;
}

.GestionTarifsLocation .listeProduits .select {
    display: none;
}

@media screen and (max-width: 640px) {
    .GestionTarifsLocation .listeProduits .select {
        display: block;
    }

    .GestionTarifsLocation .listeProduits table {
        display: none;
    }
}