.editableField{
    min-height: 25px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    color: var(--title-color-2);
}

.editableField:not(.dateTime) input{
    margin: auto;
    font-family: var(--font);
    font-weight: 600;
    color: var(--title-color-2);
    border: none;
    border-bottom: 2px solid var(--text-color);
    background-color: transparent;
    outline:none;
}

.editableField span{
    margin: auto;
}

.editableField svg {
    color: var(--title-color-2);
}

.plusMinus{
    padding: none;
}

.plusMinus>div{
    display: flex;
    margin: auto;
}

.plusMinus span, .plusMinus input{
    width: 100%;
    margin: auto 0!important;
    display: block;
    text-align: center;
}

.plusMinus button{
    margin: 0!important;
    padding: 0;
    display: flex;
}

.plusMinus button svg {
    width: 18px;
    height: 18px;
    margin: auto 0;
}

.editableField.dateTime{
    width: 215px;
}

@media screen and (max-width: 640px) {
    .editableField.plusMinus button {
        display: none;
    }
}