.Block .footer{
    display: flex;
    justify-content: end;
    margin-top: auto;
}

.Block .underlinedButton{
    margin-right: 3em;
}

/*-------------------- left -------------------- */

.listeProduits .select {
    display: none;
}

.listeProduits{
    display: flex;
    flex-direction: column;
}

.listeProduits table{
    border-collapse: collapse;
    width: 100%;
}

.listeProduits td:last-child{
    text-align: end;
}

.listeProduits .ligneFamille td{
    padding: 0.5em;
    font-weight: 600;
}

.listeProduits .ligneProduit td{
    padding: 0.3em 0.7em;
}

.listeProduits .ligneProduit:hover, .listeProduits .ligneProduit.selected{
    opacity: 0.5;
    cursor: pointer;
}

.listeProduits td svg{
    color: var(--text-color);
    width: 15px;
    height: 15px;
}


/*-------------------- right -------------------- */

.formProduit{
    position: relative;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
}

.formProduit>div:not(.formFiller){
    display: flex;
}

.formProduit h3{
    margin-bottom: 2em;
    margin-top: 2em;
}

.formColumn {
    margin: 0 0.5em;
    display: flex;
    flex-direction: column;
    width: 50%;
}

.formColumn:nth-child(1){
    display: grid;
    column-gap: 0.75em;
    row-gap: 0.75em;
    padding: 0.5em;
    grid-template-columns: repeat(20, 1fr);
    padding-left: 1em;
}

.formColumn:nth-child(1)>*{
   grid-column: 1 / 21;
}

.formColumn .error input, .formColumn .error select {
    border-color: var(--error-color);
}


.formColumn .code{
    display : flex;
    grid-column: 1 / 16;
}

.formColumn .code .valid input {
    border-color: var(--success-color);
}

.formColumn .code .valid label{
    color:  var(--success-color);
}

.formColumn .checkCode, .formColumn .tooltip {
    margin-left : 0.5em;
    width: 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formColumn .checkCode .error{
    color: var(--error-color);
}

.formColumn .checkCode .check{
    color: var(--success-color);
}

.formColumn .tooltip{
    grid-column: 16 / 16;
}

.formColumn .famille, .formColumn .prixAchat {
    grid-column: 1 / 11;
}

.formColumn .fournisseur, .formColumn .prixVente{
    grid-column: 11 / 21;
}


.formColumn:nth-child(2){
    padding-left: 1em;
    border-left: 1px solid var(--text-color-light);
}

.formColumn>span{
    width: fit-content;
}

.colorList {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
}


/*--------------------------- form modification --------------------------------*/


.formModification {
    width: 100%;
    display: grid;
    column-gap: 0.75em;
    row-gap: 0.75em;
    padding: 0.5em;
    grid-template-columns: repeat(20, 1fr);
    padding-left: 1em;
}

.formModification>* {
    grid-column: 1 / 21;
}
 
.formModification .famille {
    grid-column: 1 / 11;
}

.formModification .codeProduit {
    grid-column: 1 / 11;
}

.formModification .fournisseur {
    grid-column: 11 / 21;
}

.formModification .prixAchat {
    grid-column: 1 / 6;
}

.formModification .prixVente {
    grid-column: 6 / 11;
}



@media screen and (max-width: 640px) {  

    .formModification .codeProduit {
        grid-column: 1 / 15;
    }

    .formModification .prixAchat {
        grid-column: 1 / 11;
    }
    
    .formModification .prixVente {
        grid-column: 11 / 21;
    }

    .formProduit>div:not(.formFiller){
        flex-direction: column;
    }

    .formColumn{
        width: 100%;
    }

    .formColumn:nth-child(2){
        border: none;
    }

    .formColumn:nth-child(1){
        padding-left: 0.5em;
        margin-left: 0;
    }

    .footer{
        display: flex;
        flex-direction: row!important;
        padding-top: 2em;
        /* justify-content: space-evenly; */
    }   
    
    .listeProduits .select {
        display: block;
    }

    .listeProduits table {
        display: none;
    }
}