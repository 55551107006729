.Planning .Block {
    position: relative;
    display: flex;
    padding-bottom: 0.5em!important;
    justify-content: center;
}


.Planning .Block iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}