.Filler{
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    right: 0.5em;
    bottom: 0.5em;
}

.Filler>div{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px dashed var(--text-color-light);
}

.Filler svg {
    height: 100px;
    width: 100px;
}

.Filler>div>*{
    text-align: center;
    margin: 0.5em auto;
}